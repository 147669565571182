import React from 'react'

export default function Navbar() {
  return (
    <header className="bg-zinc-900 md:sticky top-0 z-10">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <img src="/logo.png" width={40} alt="logo"/> 
            <p className="title-font font-medium text-white mb-4 md:mb-0">
              <p className="ml-3 text-xl">
                Wolfer Status
              </p>
            </p>
            <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 flex flex-wrap items-center text-base justify-end">
                <a href="https://wolfer.fun" className="mr-5 hover:text-white">Main website</a>
            </nav>
        </div>
    </header>
  )
}
