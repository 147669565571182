import React from 'react'
import Icon from '@mdi/react'
import { mdiEmoticonHappyOutline,mdiEmoticonSadOutline, mdiAccessPointNetwork, mdiClockOutline } from '@mdi/js'

export default function Monitor(props) {
  const [montiorInfo, setMontiorInfo] = React.useState({
    id: 0, cpuFrequency: 0, cpuUsage: 0, ramUsage: 0, swapUsage: 0, disksUsage: 0, iowait: 0, rx: 0, tx: 0, diskData: null, responseTime: 0, status: "UP", timestamp: 0
  });
  React.useEffect(() => {
    fetch(`https://corsproxy.io/?https://bmonitord.fr6.xshadow.xyz/api/heartbeat/${props.monitor.id}/page?size=5&page=0&sort=timestamp,desc`).then((res) => res.json()).then((res) => {
      setMontiorInfo(res.data.content[0]);
    });
  }, [props.monitor.id]);
  return (
      <div className="p-4 md:w-1/2 w-full">
          <div className="h-full bg-zinc-900 p-8 rounded flex-row flex justify-between items-center">
              <div className='flex flex-col'>
                <h1 className="title-font text-lg font-medium text-white mb-2">{props.monitor.name}</h1>
                <div className='flex flex-row gap-3'>
                  <p>{montiorInfo.responseTime} ms</p>
                </div>
              </div>
              <div className='flex flex-col'>
                <div className='flex flex-row items-center'>
                  <Icon 
                    path={props.monitor.lastStatus === "UP" ? mdiEmoticonHappyOutline : mdiEmoticonSadOutline} 
                    size={1}
                    className={`mr-1 ${props.monitor.lastStatus === "UP" ? 'text-green-500' : 'text-red-500'}`}
                  />
                  <h1 className={`text-lg font-bold ${props.monitor.lastStatus === "UP" ? 'text-green-500' : 'text-red-500'}`}> {props.monitor.lastStatus === "UP" ? "Online" : "Offline"}</h1>
                </div>
                <h1 className='text-right text-lg'>{props.monitor.uptime}%</h1>
              </div>
              
          </div>
      </div>
  )
}
