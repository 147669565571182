import Footer from './components/Footer';
import Navbar from './components/Navbar';
import React from 'react';
import { data, fetchMonitors } from './data/monitors';
import Monitor from './components/Monitor';

function App() {
  const [monitors, setMonitors] = React.useState(data.monitors);
  const [categories, setCategories] = React.useState(data.categories);
  React.useEffect(() => {
    fetch('https://corsproxy.io/?https://bmonitord.fr6.xshadow.xyz/api/statuspage/1/public').then((res) => res.json()).then((res) => {
      setMonitors(res.data.monitors);
    });
  }, []);
  return (
    <main className="text-gray-400 bg-zinc-800 body-font h-screen">
      <Navbar />
      {monitors.length === 0 ? <p className='text-center mt-10'>Loading...</p> : <></>}
      <div className="container px-5 py-5 mx-auto">
          <div className="flex flex-wrap -m-4">
            {monitors.map((monitor) => {
              return (<Monitor key={monitor.id} monitor={monitor} />)
            })}
          </div>
      </div>
      <Footer/>
    </main>
  );
}

export default App;
